

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";


const SingleCoin = () => {
  const [topCoins, setTopCoins] = useState([]);
  const { locales,  locale: activeLocale } = useRouter();
  useEffect(() => {
    const fetchTopCoins = async () => {
      try {
        const response = await axios.get(
          'https://yallacap-sever-974c52679fc4.herokuapp.com/top');
        console.log(response.data)
        setTopCoins(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTopCoins();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (<div className={'logo-slider' + (activeLocale == "en" ? "" : "-rtl")}>

    {<div className="cont">
      {topCoins.map((coin) => (


        <a
          href="#"
          className="img mr-2 ml-2 flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700"
          key={coin.id}
        >
          {/* <figure className="mr-4 ml-2 shrink-0"> */}
            <img
              src={coin.image}
              alt="coin icon"
              width="30"
              height="30"
              className="h-12 w-12 rounded-2lg mr-4 ml-2 "
              // loading="lazy"
            />
          {/* </figure> */}



          <div style={{ width: '100%' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="block font-display font-semibold text-jacarta-700 dark:text-white" style={{ display: 'inline-block', marginRight: '10px', whiteSpace: 'nowrap' }}>

                <FormattedMessage id={`coin.${coin.symbol.toUpperCase()}`}>
                  {
                    (message) =>
                      message.length > 0 && message[0].includes("coin.")
                        ? coin.name
                        : message[0]
                  }
                </FormattedMessage>

              </span>
              {/* <div className="crypto-price__name flex-1 text-sm font-display font-semibold hidden-mobile" ></div> */}
              <span className="content">🔥</span>
            </div>
            <span className="text-sm dark:text-jacarta-300" style={{ whiteSpace: 'nowrap' }}>
              <div className='row-parent'>
                {"$"}<span className=''>{commafy(coin.current_price)}</span>  {" "}
                {coin.price_change_percentage_24h > 0
                  ?
                  // <div className=‘row-parent’>
                  <span className=" text-green" style={{ paddingRight: 7 }}><FontAwesomeIcon icon={faCaretUp} style={{ color: "green", marginTop: 2, marginRight: 2, height: 15, display: 'inline-block', paddingLeft: 7 }} />{coin.price_change_percentage_24h.toFixed(2)}%</span>
                  // </div>
                  :
                  // <div  style={{float:“left”}}>
                  <>
                    <span className="text-red" style={{ paddingRight: 7 }}><FontAwesomeIcon icon={faCaretDown} style={{ color: "red", marginTop: 2, marginRight: 2, height: 15, display: 'inline-block', paddingLeft: 7 }} />{coin.price_change_percentage_24h.toFixed(2)}%</span>
                  </>
                  // </div>
                }
              </div>

            </span>

          </div>
        </a>


        // <div key={coin.id}>
        //   <h3>{coin.name}</h3>
        //   <p>Price: ${coin.current_price}</p>
        //   <p>24h Change: {coin.price_change_percentage_24h}%</p>
        // </div>
      ))}
    </div>
    }


  </div>);

  return (



    <Slider {...settings}>
      {topCoins.map((coin) => (
        <>

          <div className=" ">

            <a
              href="#"
              className="mr-2 ml-2 flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700"
              key={coin.id}
            >
              <figure className="mr-4 shrink-0">
                <img
                  src={coin.image}
                  alt="coin icon"
                  width="30"
                  height="30"
                  className="h-12 w-12 rounded-2lg"
                  loading="lazy"
                />
              </figure>



              <div style={{ width: '100%' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span className="block font-display font-semibold text-jacarta-700 dark:text-white" style={{ display: 'inline-block', marginRight: '10px', whiteSpace: 'nowrap' }}>

                    <FormattedMessage id={`coin.${coin.symbol.toUpperCase()}`}>
                      {
                        (message) =>
                          message.length > 0 && message[0].includes("coin.")
                            ? coin.name
                            : message[0]
                      }
                    </FormattedMessage>

                  </span>
                  {/* <div className="crypto-price__name flex-1 text-sm font-display font-semibold hidden-mobile" ></div> */}
                  <span className="content">🔥</span>
                </div>
                <span className="text-sm dark:text-jacarta-300" style={{ whiteSpace: 'nowrap' }}>
                  <div className='row-parent'>
                    {"$"}<span className=''>{commafy(coin.current_price)}</span>  {" "}
                    {coin.price_change_percentage_24h > 0
                      ?
                      // <div className=‘row-parent’>
                      <span className=" text-green" style={{ paddingRight: 7 }}><FontAwesomeIcon icon={faCaretUp} style={{ color: "green", marginTop: 2, marginRight: 2, height: 15, display: 'inline-block', paddingLeft: 7 }} />{coin.price_change_percentage_24h.toFixed(2)}%</span>
                      // </div>
                      :
                      // <div  style={{float:“left”}}>
                      <>
                        <span className="text-red" style={{ paddingRight: 7 }}><FontAwesomeIcon icon={faCaretDown} style={{ color: "red", marginTop: 2, marginRight: 2, height: 15, display: 'inline-block', paddingLeft: 7 }} />{coin.price_change_percentage_24h.toFixed(2)}%</span>
                      </>
                      // </div>
                    }
                  </div>

                </span>

              </div>
            </a>

          </div>

        </>
        // <div key={coin.id}>
        //   <h3>{coin.name}</h3>
        //   <p>Price: ${coin.current_price}</p>
        //   <p>24h Change: {coin.price_change_percentage_24h}%</p>
        // </div>
      ))}
    </Slider>
  );
};

export default SingleCoin;

function commafy(num) {
  var str = num.toString().split('.');
  if (str[0].length > 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 3) {
    str[1] = str[1].slice(0, 2)
    // str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}