import CryptoTableList from "./CryptoTableList";

import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import axios from "axios";
import Moeda from "./Moeda";
import Tippy from "@tippyjs/react";


const index = () => {
  const { locales, locale: activeLocale } = useRouter();
  const intl = useIntl();
  const title = intl.formatMessage({ id: "page.home.loadmore" });
  const [moedas, setMoedas] = useState([]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [showESGTooltip, setShowESGTooltip] = useState(false);

  const [isDes, setSortIsDes] = useState(true);
  const [sortProp, setSortProp] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  function setActiveOrder() {

  }


  const handleloadMore = () => {
    setShowAll(true);
    //useEffect(() => {  }, []);
    // useEffect(() => {
    // axios
    //   .get(
    //     "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    //   )
    //   .then((res) => {
    //     setMoedas(res.data);
    //     setLoadMoreBtn(false);
    //     console.log(res.data);
    //   })
    //   .catch((error) => console.log(error));
    // }, []);

  };

  const handleChange = (event) => {

    var coins = moedas.filter(item =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      item.symbol.toLowerCase().includes(event.target.value.toLowerCase())
    );

    setFilteredData(coins)
  };

  const handleNameSort = () => {
    setSortProp("name");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return isDes ? 1 : -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };

  const handlePriceSort = () => {
    setSortProp("price");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.current_price < b.current_price) {
        return isDes ? 1 : -1;
      } else if (a.current_price > b.current_price) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };


  const handleRankSort = () => {
    setSortProp("rank");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.market_cap_rank < b.market_cap_rank) {
        return isDes ? 1 : -1;
      } else if (a.market_cap_rank > b.market_cap_rank) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };
  const handleVolumeSort = () => {
    setSortProp("volume");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.market_cap_change_24h < b.market_cap_change_24h) {
        return isDes ? 1 : -1;
      } else if (a.market_cap_change_24h > b.market_cap_change_24h) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };

  const handleChangeSort = () => {
    setSortProp("change");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.price_change_percentage_24h < b.price_change_percentage_24h) {
        return isDes ? 1 : -1;
      } else if (a.price_change_percentage_24h > b.price_change_percentage_24h) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };


  const handle1hSort = () => {
    setSortProp("1h");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.price_change_percentage_1h_in_currency < b.price_change_percentage_1h_in_currency) {
        return isDes ? 1 : -1;
      } else if (a.price_change_percentage_1h_in_currency > b.price_change_percentage_1h_in_currency) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };


  const handle24hSort = () => {
    setSortProp("24h");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.price_change_percentage_24h_in_currency < b.price_change_percentage_24h_in_currency) {
        return isDes ? 1 : -1;
      } else if (a.price_change_percentage_24h_in_currency > b.price_change_percentage_24h_in_currency) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };


  const handle7dSort = () => {
    setSortProp("7d");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.price_change_percentage_7d_in_currency < b.price_change_percentage_7d_in_currency) {
        return isDes ? 1 : -1;
      } else if (a.price_change_percentage_7d_in_currency > b.price_change_percentage_7d_in_currency) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };


  const handleMarketCapSort = () => {
    setSortProp("marketcap");
    setSortIsDes(!isDes);

    var coins = filteredData.sort((a, b) => {
      if (a.market_cap < b.market_cap) {
        return isDes ? 1 : -1;
      } else if (a.market_cap > b.market_cap) {
        return isDes ? -1 : 1;
      } else {
        return 0;
      }
    });

    setFilteredData(coins)
  };

  useEffect(() => {
    axios
      .get(
        "https://yallacap-sever-974c52679fc4.herokuapp.com/markets"
      )
      .then((res) => {
        setMoedas(res.data);
        setFilteredData(res.data);
        // let coinjson = res.data.map(function(c){
        //   return {`coins.${c.symbol}`: `${c.name}`}
        // })
        console.log(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  var coins = !showAll ? filteredData.slice(0, 10) : filteredData;

  const filteredMoedas = coins.filter((moeda) => moeda.name.toLowerCase());

  return (
    <>
      {/* <!-- Crypto Prices --> */}

      <div className="mx-5 sm:mx-20">
        <div className="flex items-center dark:border-jacarta-600 border-jacarta-100 border  text-sm ">
          <form
            action="search"
            style={{ marginLeft: 20, marginRight: 20 }}
            className="relative  mb-5 mt-5  lg:block xl:ml-[15%]"
          >
            <input
              type="search"
              className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
              placeholder={intl.formatMessage({ id: "page.home.search" })}

              onChange={handleChange}
            />
            <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-500 h-4 w-4 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
              </svg>
            </span>
          </form>

        </div>
        <div className="scrollbar-custom overflow-x-auto" >
          <table id="table-1" className="rounded-lg bg-white dark:bg-jacarta-700 dark:text-jacarta-300 w-[100%]" >
            <thead style={{ borderBottom: '.5px solid #80808080', height: 70}}>
              <tr>
                <th style={{width: "3%"}}></th>
                <th> 
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img style={{ width:45, paddingLeft: '10px', paddingRight: '10px'}} src={`/images/esg-header.png`} onMouseEnter={() => setShowESGTooltip(true)}
            onMouseLeave={() => setShowESGTooltip(false)} />
             {showESGTooltip && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '90%',
                transform: activeLocale === "en" ? 'translateX(-5%)' : 'translateX(-100%)',
                backgroundColor: '#fff',
                color: '#000',
                padding: '5px',
                //whiteSpace: 'nowrap',
                zIndex: 5000,
                width: '400px',
                borderRadius: '5px',
              }}
            >
                            {intl.formatMessage({ id: "page.home.esg.rating" })}

             
             
            </div>
          )}
          </div>
</th>
                <th>  <div style={{ position: 'relative', display: 'inline-block' }}>
                <img style={{maxWidth:30}} src={`/images/header.png`} onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}/>
                {showTooltip && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '90%',
                transform: activeLocale === "en" ? 'translateX(-5%)' : 'translateX(-100%)',
                backgroundColor: '#fff',
                color: '#000',
                padding: '5px',
                whiteSpace: 'nowrap',
                zIndex: 5000,
                width: 'auto',
                borderRadius: '5px',
              }}
            >
              {intl.formatMessage({ id: "page.home.esg.head" })}
             
      
            </div>
          )}
                </div>
                </th>
                
                <th style={{width: "5%"}}>   <a href='javascript:void(0)' className={"table-header hidden dark:text-white sm:block " + ("rank" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")} onClick={handleRankSort} >#</a></th>
                <th style={{ textAlign: activeLocale === "en" ? 'left' : 'right'}}><a href='javascript:void(0)' onClick={handleNameSort} className={"table-header  dark:text-white  px-3 py-5 " + ("name" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")} style={{marginLeft:15, marginRight:15}}>{intl.formatMessage({ id: "page.home.table.name" })}</a></th>
                <th style={{textAlign: 'right'}}>    <a href='javascript:void(0)' onClick={handlePriceSort} className={"table-header  dark:text-white   px-3 py-5 text-right " + ("price" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.price" })}
                </a></th>
                <th>  <a href='javascript:void(0)' onClick={handle1hSort} className={"table-header dark:text-white   " + ("1h" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.1h" })}
                </a></th>
                <th>  <a href='javascript:void(0)' onClick={handle24hSort} className={"table-header dark:text-white  " + ("24h" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.24h" })}
                </a></th>
                <th>  <a href='javascript:void(0)' onClick={handle7dSort} className={"table-header dark:text-white  " + ("7d" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.7d" })}
                </a></th>
                <th>    <a href='javascript:void(0)' onClick={handleVolumeSort} className={"table-header dark:text-white  " + ("volume" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.vol24" })}
                </a></th>
                <th>   <a href='javascript:void(0)' onClick={handleMarketCapSort} className={"table-header dark:text-white  " + ("marketcap" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.marketcap" })}
                </a></th>
                <th>   <a href='javascript:void(0)' onClick={handleChangeSort} className={"table-header dark:text-white  " + ("change" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                {intl.formatMessage({ id: "page.home.table.change24" })}
                </a></th>
                <th>   <a href='javascript:void(0)' className="table-header dark:text-white" style={{cursor: 'default'}}>
                {intl.formatMessage({ id: "page.home.table.last7d" })}
                </a></th>
              </tr>
            </thead>
            <tbody>
              {filteredMoedas.map((moeda) => {
                return (
                  <Moeda
                  keyId={moeda.id}
                    name={moeda.name}
                    rank={moeda.market_cap_rank}
                    price={moeda.current_price}
                    oneh={moeda.price_change_percentage_1h_in_currency}
                    day={moeda.price_change_percentage_24h_in_currency}
                    sevenday={moeda.price_change_percentage_7d_in_currency}
                    symbol={moeda.symbol}
                    volume={moeda.market_cap_change_24h}
                    marketcap={moeda.market_cap}
                    image={moeda.image}
                    priceChange={moeda.price_change_percentage_24h}
                    sparkline={moeda.sparkline_in_7d.price}
                  />
                );
              })}
             
            </tbody>
          </table>
          {!showAll && filteredData.length > 10 && (
                <div className="row text-center ">
                  <button
                    onClick={handleloadMore}
                    className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all mb-5 mt-5 button-with-image"
                  >
                    {title}
                    <img src="/favicon.png" width="24" className="button-image" />
                  </button>


                </div>
              )}

          <div className="rounded-lg bg-white dark:bg-jacarta-700 dark:text-jacarta-300">
            <div className="flex items-center dark:border-jacarta-600 border-jacarta-100 border  text-sm ">
              {/* <div className="w-[2%]  py-2 px-2"></div>
                              
                                <div className="w-[11%]  py-2 px-2"></div>
                                <div className="w-[4%]">
                                <Tippy content={<span  style={{padding: 10, marginTop:20, marginBottom: 20}}>Sharia Compliance Opinion</span>}>
                                <img style={{width: 30 }} src={`/images/header.png`}/>
                                </Tippy>
                                </div>
                <a href='javascript:void(0)' className={"table-header hidden w-[8%] pl-4 sm:block lg:pl-10 "+ ("rank" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")} onClick={handleRankSort} >#</a>
                <a  href='javascript:void(0)' onClick={handleNameSort} className={"table-header lg:w-[35%] px-3 py-5 " + ("name" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>Name</a>
                <a  href='javascript:void(0)' onClick={handlePriceSort} className={"table-header w-[20%] px-3 py-5 text-right lg:w-[24%] "+ ("price" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  Price
                </a>
                <a  href='javascript:void(0)' onClick={handle1hSort} className={"table-header w-[10%] px-3 py-5 text-right lg:w-[10%] "+ ("1h" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  1h
                </a>
                <a  href='javascript:void(0)' onClick={handle24hSort} className={"table-header w-[10%] px-3 py-5 text-right lg:w-[10%] "+ ("24h" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  24h
                </a>
                <a  href='javascript:void(0)' onClick={handle7dSort} className={"table-header w-[10%] px-3 py-5 text-right lg:w-[10%] "+ ("7h" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  7d
                </a>
                <a  href='javascript:void(0)' onClick={handleVolumeSort} className={"table-header w-[22%] hidden px-3 py-5 text-right md:block "+ ("volume" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  Volume (24h)
                </a>
                <a  href='javascript:void(0)' onClick={handleMarketCapSort} className={"table-header w-[20%] px-3 py-5 text-right lg:w-[15%] " + ("marketcap" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  Market Cap
                </a>
                <a  href='javascript:void(0)' onClick={handleChangeSort} className={"table-header w-[17%] px-3 py-5 text-right lg:w-[14%] " + ("change" == sortProp ? (isDes ? 'arrow-down' : 'arrow-up') : "")}>
                  Change (24h)
                </a> */}

              {/* <div className="w-[10%]  pl-3 pr-4 text-right">Action</div> */}
            </div>
            {/* End header */}

            {/* <div className="divide-y divide-jacarta-100 dark:divide-jacarta-600">
                {filteredMoedas.map((moeda) => {
                  return (
                    <Moeda
                      key={moeda.id}
                      name={moeda.name}
                      rank={moeda.market_cap_rank}
                      price={moeda.current_price}
                      oneh={moeda.price_change_percentage_1h_in_currency}
                      day={moeda.price_change_percentage_24h_in_currency}
                      sevenday={moeda.price_change_percentage_7d_in_currency}
                      symbol={moeda.symbol}
                      volume={moeda.market_cap_change_24h}
                      marketcap={moeda.market_cap}
                      image={moeda.image}
                      priceChange={moeda.price_change_percentage_24h}
                    />
                  );
                })}
                    {!showAll && filteredData.length > 10 && (
                      <div className="row text-center ">

                        
                        <button
                          onClick={handleloadMore}
                          className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all mb-5 mt-5 button-with-image"
                        >
                          {title}
                          <img src="/favicon.png" width="24" className="button-image" />
                        </button>
                        

                      </div>
                    )}
              </div> */}
            {/* pricing-table */}


            {/* <div className="border-t border-jacarta-100 px-4 pt-4  text-center dark:border-jacarta-600">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center text-sm text-accent"
                >
                  View more markets
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    className="ml-1 fill-accent"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </a>
              </div> */}
            {/* End viewmore */}
          </div>
        </div>
      </div>
      {/* <!-- end crypto prices -->  */}
    </>
  );
};

export default index;

