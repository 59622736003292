import Link from "next/link";
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useIntl, FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

const SparklineChart = ({ data }) => {
  // Register the category scale with Chart.js
  const intl = useIntl();
  const title = intl.formatMessage({ id: "page.home.table.price" });
  const chartData = {
    labels: data.map((_, i) => i),
    datasets: [
      {
        label: `${title}`,
        data: data,
        fill: false,
        borderColor: "#8358FF",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  }

  return <Line data={chartData} options={options} height={50} width={100} />;
};
// const SparklineChart = ({ data }) => {
//   const minValue = Math.min(...data);
//   const maxValue = Math.max(...data);

//   const height = 50;

//   const pointWidth = 5;

//   return (
//     <svg width={data.length * pointWidth} height={height}>
//       {data.map((value, index) => (
//         <rect
//           key={index}
//           x={index * pointWidth}
//           y={(1 - (value - minValue) / (maxValue - minValue)) * height}
//           width={pointWidth}
//           height={(value - minValue) / (maxValue - minValue) * height}
//           fill="#8358FF"
//         />
//       ))}
//     </svg>
//   );
// };
// }

const Moeda = ({
  keyId,
  name,
  price,
  symbol,
  marketcap,
  volume,
  image,
  priceChange,
  rank,
  oneh,
  day,
  sevenday,
  sparkline
}) => {

  const intl = useIntl();

  const [showTooltip, setShowTooltip] = useState(false);
  const [showESGTooltip, setShowESGTooltip] = useState(false);

  const { locales, locale: activeLocale } = useRouter();

  var sharia = coinsSharia.find(item => item['coin'].toLocaleLowerCase().includes(symbol));

  return (

    <tr style={{ borderBottom: '.5px solid #80808080' }}>
      <th><div style={{ paddingLeft: '15px', paddingRight: '10px' }}>
        <StarIcon />
      </div></th>
     

      <th>

        <div style={{ position: 'relative', display: 'inline-block', width: 45, paddingLeft: '10px', paddingRight: '10px' }}>
          <img style={{ width: 100 }} src={`/images/esg/${sharia ? sharia['esg'] : "B"}.png`} onMouseEnter={() => setShowESGTooltip(true)}
            onMouseLeave={() => setShowESGTooltip(false)} />

          {showESGTooltip && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '90%',
                transform: activeLocale === "en" ? 'translateX(-5%)' : 'translateX(-100%)',
                backgroundColor: '#fff',
                color: '#000',
                padding: '5px',
                //whiteSpace: 'nowrap',
                zIndex: 5000,
                width: '400px',
                borderRadius: '5px',
              }}
            >
              <div style={{ backgroundColor: `${sharia['esgColor']}`, whiteSpace: 'nowrap', }}>
                <span style={{ padding: 5, color: sharia['esg'] == "D" || sharia['esg'] == "A" ? 'white' : 'black' }}>

                  {intl.formatMessage({ id: `page.home.esg.${sharia['esg'].toLowerCase()}` })}</span>
              </div>
              {intl.formatMessage({ id: `page.home.esg.${sharia['esg'].toLowerCase()}Des` })}
            </div>
          )}
        </div></th>
        <th>

<div style={{ position: 'relative', display: 'inline-block', width: 24 }}>
  <img style={{ width: 100 }} src={`/images/sharia/${sharia ? sharia['color'] : "green"}.png`} onMouseEnter={() => setShowTooltip(true)}
    onMouseLeave={() => setShowTooltip(false)} />

  {showTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '90%',
        transform: activeLocale === "en" ? 'translateX(-5%)' : 'translateX(-100%)',
        backgroundColor: '#fff',
        color: '#000',
        padding: '5px',
        //whiteSpace: 'nowrap',
        zIndex: 5000,
        width: '400px',
        borderRadius: '5px',
      }}
    >
      <div style={{ backgroundColor: `${sharia['color']}`, whiteSpace: 'nowrap', }}>
        <span style={{ padding: 5, color: sharia['color'] == "red" || sharia['color'] == "green" ? 'white' : 'black' }}>

          {intl.formatMessage({ id: `page.home.tooltip.${sharia['color']}` })}</span>
      </div>
      {intl.formatMessage({ id: "page.home.tooltip" })}
    </div>
  )}
</div></th>
      <th style={{ paddingLeft: '5px', paddingRight: '5px' }}>{rank}</th>
      <th style={{ width: '100%' }}>  <Link href={"/item/" + (keyId && keyId.toLowerCase())}>
        <a className="crypto-price__coin flex items-center px-3 py-5">
          <img
            src={image}
            className="mr-2 flex-shrink-0"
            width="24"
            height="24"
            alt="crypto"
          />

          {/* <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
         
        
        </div> */}
          <span className="text-jacarta-600 dark:text-white mr-1 text-sm" style={{whiteSpace: 'nowrap'}}>
            <FormattedMessage id={`coin.${symbol.toUpperCase()}`}>
              {
                (message) =>
                  message.length > 0 && message[0].includes("coin.")
                    ? name
                    : message[0]
              }
            </FormattedMessage>
            {/* {intl.formatMessage({ id: `coin.${symbol.toUpperCase()}` })}  */}
          </span>
          <span style={{ marginLeft: 3, marginRight: 5 }} className="text-jacarta-400 text-xs">{symbol}</span>

        </a>
      </Link></th>

      <th style={{ textAlign: 'right' }}> <span style={{ marginLeft: 10, marginRight: 10 }} className="text-sm"> ${commafy(price)}</span> </th>




      <th style={{ direction: 'ltr' }}>{oneh < 0 ? (
        <span style={{ marginLeft: 10, marginRight: 10 }} className="text-red text-sm">{oneh && oneh.toFixed(2)}%</span>
      ) : (
        <span style={{ marginLeft: 10, marginRight: 10 }} className="text-green text-sm"> {oneh && oneh.toFixed(2)}%</span>
      )}</th>
      <th style={{ direction: 'ltr' }}>{day < 0 ? (
        <span style={{ marginLeft: 10, marginRight: 10 }} className="text-red text-sm">{day && day.toFixed(2)}%</span>
      ) : (
        <span style={{ marginLeft: 10, marginRight: 10 }} className="text-green text-sm"> {day && day.toFixed(2)}%</span>
      )}</th>
      <th style={{ direction: 'ltr' }}> {sevenday < 0 ? (
        <span style={{ marginLeft: 10, marginRight: 10 }} className="text-red text-sm">{sevenday && sevenday.toFixed(2)}%</span>
      ) : (
        <span style={{ marginLeft: 10, marginRight: 10 }} className="text-green text-sm"> {sevenday && sevenday.toFixed(2)}%</span>
      )}
      </th>
      <th style={{ textAlign: 'right', direction: 'ltr' }}>  <span  className="text-sm" style={{ marginLeft: 20, marginRight: 20, color: "gray" }} > ${commafy(volume.toFixed(0))} </span></th>
      <th style={{ textAlign: 'right', direction: 'ltr' }}> <span  className="text-sm" style={{ marginLeft: 20, marginRight: 20, color: "gray" }}> ${commafy(marketcap)}  </span></th>
      <th style={{ direction: 'ltr', paddingLeft: '10px', paddingRight: '10px' }}> {priceChange < 0 ? (
        <span className="text-red text-sm">{priceChange.toFixed(2)}%</span>
      ) : (
        <span className="text-green text-sm"> {priceChange.toFixed(2)}%</span>
      )}</th>
      <th style={{paddingLeft: '10px', paddingRight: '10px' }}>
      <SparklineChart data={sparkline}/>
      </th>
    </tr>
  )



};

export default Moeda;
function commafy(num) {
  var str = num.toString().split('.');
  if (str[0].length > 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 3) {
    str[1] = str[1].slice(0, 2)
    // str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}


function StarIcon(props) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <>
      <span className="text-jacarta-700 text-base dark:text-white text-center">
        <FontAwesomeIcon  onClick={handleClick} icon={faStar} style={{ height: 20, width: 20, display: 'inline',color: isClicked ? 'yellow' : 'grey', cursor: 'pointer' }}></FontAwesomeIcon></span>

      {/* <div style={{width:'25px', display: 'inline !important'}}>
    <FontAwesomeIcon
      onClick={handleClick}
      icon={faStar}
      style={{ color: isClicked ? 'yellow' : 'white', cursor: 'pointer', display: 'inline' }}
    />
</div> */}
    </>
  );
}



var coinsSharia = [
  {
    "coin": "BitcoinBTC",
    "color": "green",
    "esg": "D",
    "esgColor": "red"
  },
  {
    "coin": "EthereumETH",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "TetherUSDT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "BNBBNB",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "USD CoinUSDC",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "XRPXRP",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "CardanoADA",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "DogecoinDOGE",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "Lido Staked EtherSTETH",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "PolygonMATIC",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "Binance USDBUSD",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "SolanaSOL",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "PolkadotDOT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "LitecoinLTC",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "Shiba InuSHIB",
    "color": "green",
    "esg": "D",
    "esgColor": "red"
  },
  {
    "coin": "TRONTRX",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "AvalancheAVAX",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "DaiDAI",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "UniswapUNI",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Wrapped BitcoinWBTC",
    "color": "green",
    "esg": "D",
    "esgColor": "red"
  },
  {
    "coin": "ChainlinkLINK",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Cosmos HubATOM",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "LEO TokenLEO",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "ToncoinTON",
    "color": "orange",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "MoneroXMR",
    "color": "green",
    "esg": "D",
    "esgColor": "red"
  },
  {
    "coin": "Ethereum ClassicETC",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "OKBOKB",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Bitcoin CashBCH",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "StellarXLM",
    "color": "gold",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "FilecoinFIL",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "AptosAPT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "TrueUSDTUSD",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Lido DAOLDO",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "HederaHBAR",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "QuantQNT",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "CronosCRO",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "NEAR ProtocolNEAR",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "VeChainVET",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "ArbitrumARB",
    "color": "orange",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "ApeCoinAPE",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "AlgorandALGO",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Internet ComputerICP",
    "color": "orange",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "StacksSTX",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "EOSEOS",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "FantomFTM",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "The GraphGRT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "The SandboxSAND",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "DecentralandMANA",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "MultiversXEGLD",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "FraxFRAX",
    "color": "orange",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "TezosXTZ",
    "color": "green",
    "esg": "A",
    "esgColor": "#037d0e"
  },
  {
    "coin": "AaveAAVE",
    "color": "red",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "FlowFLOW",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Theta NetworkTHETA",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "ImmutableXIMX",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Axie InfinityAXS",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "NEONEO",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "KuCoinKCS",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Synthetix NetworkSNX",
    "color": "red",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Pax DollarUSDP",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "WhiteBIT TokenWBT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "BitDAOBIT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Rocket PoolRPL",
    "color": "red",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "GateGT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "USDDUSDD",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Terra Luna ClassicLUNC",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "OptimismOP",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Mina ProtocolMINA",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "KlaytnKLAY",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "Curve DAOCRV",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Bitcoin SVBSV",
    "color": "green",
    "esg": "D",
    "esgColor": "red"
  },
  {
    "coin": "ConfluxCFX",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "PancakeSwapCAKE",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "DashDASH",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "ChilizCHZ",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "MakerMKR",
    "color": "red",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "GMXGMX",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "eCashXEC",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "HuobiHT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "IOTAMIOTA",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "BitTorrentBTT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "SingularityNETAGIX",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Frax ShareFXS",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Bitget TokenBGB",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "cETHCETH",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "XDC NetworkXDC",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "EdgecoinEDGT",
    "color": "orange",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "PAX GoldPAXG",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Trust WalletTWT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Tokenize XchangeTKX",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "Tether GoldXAUT",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "cUSDCCUSDC",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "ZilliqaZIL",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "OsmosisOSMO",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "FlareFLR",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "RenderRNDR",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "RadixXRD",
    "color": "green",
    "esg": "C",
    "esgColor": "gold"
  },
  {
    "coin": "Mask NetworkMASK",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "LoopringLRC",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  },
  {
    "coin": "1inch1INCH",
    "color": "green",
    "esg": "B",
    "esgColor": "#4cf95b"
  }
];