import SingleCoin from "./SingleCoin";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";

const index = () => {
  const { locales,  locale: activeLocale } = useRouter();
  const intl = useIntl();
  const title = intl.formatMessage({ id: "page.home.head.title" });


  return (
    <>
      <section className="relative bg-light-base pb-12 dark:bg-jacarta-900">
        <div className="container">
          <div >
            <h2 className="ml-5 mb-6 font-display sm:text-2xl text-lg font-medium text-jacarta-700 dark:text-white" style={{textAlign: activeLocale == "en"  ? 'left' : 'right'}}>
                {title}
            </h2>
          </div>
            <SingleCoin />

        </div>
      </section>
    </>
  );
};

export default index;
